export const canonicalURL = (router, direct = false) => {
  let CANONICAL_DOMAIN = 'https://www.crackthecode.la';

  const _pathSliceLength = Math.min.apply(Math, [
    router.asPath.indexOf('?') > 0 ? router.asPath.indexOf('?') : router.asPath.length,
    router.asPath.indexOf('#') > 0 ? router.asPath.indexOf('#') : router.asPath.length
  ]);

  if (direct) {
    return (
      CANONICAL_DOMAIN +
      router.asPath.replace('direct/', '').substring(0, _pathSliceLength)
    );
  }

  return CANONICAL_DOMAIN + router.asPath.substring(0, _pathSliceLength);
};
