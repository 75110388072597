export const ColombiaFlag = ({ width = '32', height = '25' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6_3762)">
      <rect
        x="32"
        y="3"
        width="22"
        height="32"
        rx="4"
        transform="rotate(90 32 3)"
        fill="#001F19"
      />
      <g clipPath="url(#clip1_6_3762)">
        <path d="M32 25L32 3L1.90735e-06 3L9.45698e-07 25L32 25Z" fill="#D91023" />
      </g>
    </g>
    <path d="M32 20L32 13L0 13L-3.0598e-07 20L32 20Z" fill="#0041AA" />
    <path
      d="M32 13L32 7C32 4.79086 30.2091 3 28 3L4 3C1.79086 3 -7.8281e-08 4.79086 -1.74846e-07 7L-4.37114e-07 13L32 13Z"
      fill="#FCD116"
    />
    <defs>
      <clipPath id="clip0_6_3762">
        <rect
          x="32"
          y="3"
          width="22"
          height="32"
          rx="4"
          transform="rotate(90 32 3)"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_6_3762">
        <rect
          width="22"
          height="32"
          fill="white"
          transform="translate(32 3) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const PeruFlag = ({ width = '30', height = '20' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6_3738)">
      <rect y="3" width="32" height="21.3333" rx="4" fill="#001F19" />
      <g clipPath="url(#clip1_6_3738)">
        <path d="M32 3H0V24.3333H32V3Z" fill="#D91023" />
        <path d="M21.3332 3H10.6665V24.3333H21.3332V3Z" fill="white" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_6_3738">
        <rect y="3" width="32" height="21.3333" rx="4" fill="white" />
      </clipPath>
      <clipPath id="clip1_6_3738">
        <rect width="32" height="21.3333" fill="white" transform="translate(0 3)" />
      </clipPath>
    </defs>
  </svg>
);
export const MexicoFlag = ({ width = '30', height = '20' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6C0 4.89543 0.895431 4 2 4H8V20H2C0.895431 20 0 19.1046 0 18V6Z"
      fill="#006847"
    />
    <rect x="8" y="4" width="8" height="16" fill="white" />
    <path
      d="M16 4H22C23.1046 4 24 4.89543 24 6V18C24 19.1046 23.1046 20 22 20H16V4Z"
      fill="#CE1126"
    />
  </svg>
);
export const ArgentinaFlag = ({ width = '17', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6152 4.47803L0.615234 4.47803L0.615235 2.47803C0.615235 1.37346 1.51067 0.478026 2.61523 0.478026L14.6152 0.478027C15.7198 0.478027 16.6152 1.37346 16.6152 2.47803L16.6152 4.47803Z"
      fill="#AAE2FF"
    />
    <path d="M0.615234 4.47803H16.6152V11.478H0.615234V4.47803Z" fill="white" />
    <path
      d="M0.615234 11.478H16.6152V13.478C16.6152 14.5826 15.7198 15.478 14.6152 15.478H2.61523C1.51066 15.478 0.615234 14.5826 0.615234 13.478V11.478Z"
      fill="#AAE2FF"
    />
  </svg>
);
