import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import Layout from '#layout/index';
import { trustUsImg } from '#helpers/data-utils';
import { canonicalURL } from '#helpers/canonical-url';
import InstitutionalVideo from '#app/UI/organisms/institutional-video';

const StudentsTestimonials = dynamic(() =>
  import('#app/UI/organisms/students-testimonials')
);
const ProgramsForOrganizations = dynamic(() =>
  import('#app/UI/organisms/programs-for-organizations')
);
const Hero = dynamic(() => import('#app/pages/home/hero'));
const News = dynamic(() => import('#app/UI/organisms/news'));
const CeoCtc = dynamic(() => import('#app/UI/organisms/ceo-ctc'));
const WhatsApp = dynamic(() => import('#app/UI/molecules/whatsapp'));
const AnnouncementBar = dynamic(
  () => import('../components/Home/AnnouncementBar/announcement-bar'),
  { ssr: false }
);
const Statistics = dynamic(() => import('#app/UI/organisms/statistics'));
const TrustInUs = dynamic(() => import('#app/UI/organisms/trust-in-us'));
const B2bProgram = dynamic(() => import('#app/UI/organisms/b2b-program'));
const Suscription = dynamic(() => import('#app/UI/organisms/suscription'));
const ValueProposal = dynamic(() => import('#app/UI/organisms/value-proposal'));
const SuccessStories = dynamic(() => import('#app/UI/organisms/success-stories'));
const LearningPrograms = dynamic(() => import('#app/UI/organisms/learning-programs'));

// Schema for SEO
const schema = () => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'EducationalOrganization',
    name: 'Crack The Code',
    url: 'https://crackthecode.la/',
    logo: 'https://www.crackthecode.la/',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+51 924 084 937',
      contactType: 'customer service',
      areaServed: ['MX', 'PE', 'CO', '419'],
      availableLanguage: 'es'
    },
    sameAs: [
      'https://www.instagram.com/crackthecode.la/',
      'https://www.tiktok.com/@crackthecodelatam',
      'https://www.linkedin.com/company/crack-the-code-per%C3%BA/',
      'https://www.facebook.com/crackthecode.la'
    ]
  };
};

const getQueries = (queries, setUTMdata) => {
  setUTMdata({
    utm_source: queries.utm_source || '',
    utm_medium: queries.utm_medium || '',
    utm_campaign: queries.utm_campaign || '',
    referrer: document.referrer || ''
  });
};

const HomePage = () => {
  const router = useRouter();
  const [currency, setCurrency] = useState();
  const [utmData, setUTMdata] = useState(null);

  useEffect(() => {
    getQueries(router.query, setUTMdata);
  }, [router.query]);

  useEffect(() => {
    if (utmData?.utm_source !== '' && utmData !== null) {
      window?.localStorage.setItem('trackingUTM', JSON.stringify(utmData));
    }
  }, [utmData, setUTMdata]);

  useEffect(() => {
    if (router.query.cupon) {
      window?.localStorage.setItem('cupon', router.query.cupon);
    }
  }, [currency]);

  return (
    <>
      <Head>
        <title>Crack The Code | Academia de Programación y Tecnología para Niños</title>
        <meta
          name="description"
          content="Academia de ciencia de la computación. Cursos y talleres de programación, robótica educativa, desarrollo de videojuegos y filmación para niños ¡Descubre nuestros cursos!"
        />
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href={canonicalURL(router)} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema()) }}
        />
      </Head>
      <Layout
        mainType="full_variant"
        showAnnouncement
        currency={currency}
        setCurrency={setCurrency}
      >
        <section className="landing-home">
          <AnnouncementBar />

          <Hero />

          <LearningPrograms />

          <CeoCtc />

          <Statistics />

          <B2bProgram />

          <SuccessStories />

          <ValueProposal />

          <StudentsTestimonials />

          <InstitutionalVideo />

          <TrustInUs list={trustUsImg} background="white" />

          <ProgramsForOrganizations />

          <News />

          <Suscription />

          <WhatsApp
            eventNameAnalytics="whatsapp-button-home"
            msg="Deseo recibir más información sobre Crack the Code"
          />
        </section>
      </Layout>
    </>
  );
};

export default HomePage;
