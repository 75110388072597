import { ColombiaFlag, PeruFlag, MexicoFlag } from '#helpers/icons/flags-icons';

export const whyCtcList = [
  {
    title: 'Clases divertidas e interactivas',
    orientation: 'left',
    description:
      'Experiencias lúdicas en vivo, con plataformas didácticas para aprender y divertirse.',
    image: './why-learn-ctc/whyLearnCtc-1.png'
  },
  {
    title: 'Pequeños grupos multiculturales',
    orientation: 'right',
    description:
      'Promovemos el intercambio cultural y el desarrollo de la imaginación entre alumnos de toda latinoamérica.',
    image: './why-learn-ctc/whyLearnCtc-2.png'
  },
  {
    title: 'Tiempo productivo en pantalla',
    orientation: 'left',
    description:
      'Habilidades de resolución de problemas, pensamiento lógico y autonomía para toda la vida.',
    image: './why-learn-ctc/whyLearnCtc-3.png'
  }
];

export const trustUsImg = [
  {
    id: 'logo-1',
    class: 'innova',
    name: 'Innova',
    image: './trust-in-us/innova.webp'
  },
  {
    id: 'logo-2',
    class: 'unicef',
    name: 'Unicef',
    image: './trust-in-us/unicef.webp'
  },
  {
    id: 'logo-3',
    class: 'google',
    name: 'Google',
    image: './trust-in-us/google.webp'
  },
  {
    id: 'logo-4',
    class: 'mercado-libre',
    name: 'Mercado Libre',
    image: './trust-in-us/mercado-libre.webp'
  },
  {
    id: 'logo-5',
    class: 'globant',
    name: 'Globant',
    image: './trust-in-us/globant.webp'
  },
  {
    id: 'logo-6',
    class: 'fundacion-alqueria',
    name: 'Fundación Alquería',
    image: './trust-in-us/fundacion-alqueria.webp'
  },
  {
    id: 'logo-7',
    class: 'barranquilla',
    name: 'Barranquilla',
    image: './trust-in-us/barranquilla.webp'
  },

  {
    id: 'logo-8',
    class: 'velez',
    name: 'Velez',
    image: './trust-in-us/velez.webp'
  },
  {
    id: 'logo-9',
    class: 'antioquia',
    name: 'Antioquia',
    image: './trust-in-us/antioquia.webp'
  },
  {
    id: 'logo-10',
    class: 'globant',
    name: 'Ministerio Educación',
    image: './trust-in-us/ministerio-educacion.webp'
  }
];

export const benefitsProgram = [
  {
    id: 'logo-1',
    class: 'alicorp',
    name: 'Alicorp',
    image: './business/benefitsProgram/gray/logo-alicorp-gray.png'
  },
  {
    id: 'logo-2',
    class: 'globant',
    name: 'Globant',
    image: './business/benefitsProgram/gray/logo-globant-gray.png'
  },
  {
    id: 'logo-3',
    class: 'romero',
    name: 'Romero',
    image: './business/benefitsProgram/gray/logo-grupo-romero-gray.png'
  },
  {
    id: 'logo-4',
    class: 'interbank',
    name: 'Interbank',
    image: './business/benefitsProgram/gray/logo-interbank-gray.png'
  },
  {
    id: 'logo-5',
    class: 'lmicrosoft',
    name: 'Microsoft',
    image: './business/benefitsProgram/gray/logo-microsoft-gray.png'
  },
  {
    id: 'logo-6',
    class: 'logo-scotiabank',
    name: 'Scotiabank',
    image: './business/benefitsProgram/gray/logo-scotiabank-gray.png'
  }
];

export const socialProgram = [
  {
    id: 'logo-1',
    class: 'samsung',
    name: 'Samsung',
    image: './business/socialProgram/gray/logo-samsung-gray.png'
  },
  {
    id: 'logo-2',
    class: 'google',
    name: 'Google',
    image: './business/socialProgram/gray/logo-google-gray.png'
  },
  {
    id: 'logo-3',
    class: 'minedu',
    name: 'Minedu',
    image: './business/socialProgram/gray/logo-grupo-minedu-gray.png'
  },
  {
    id: 'logo-4',
    class: 'microsoft',
    name: 'Microsoft',
    image: './business/socialProgram/gray/logo-microsoft-gray.png'
  },
  {
    id: 'logo-5',
    class: 'lenovo',
    name: 'Lenovo',
    image: './business/socialProgram/gray/logo-lenovo-gray.png'
  }
];
export const levelRangeCompanies = [
  {
    id: 'logo-1',
    class: 'puj',
    name: 'PUJ',
    image: './business/ctcForSchool/grey/logo-PUJ-grey.png'
  },
  {
    id: 'logo-2',
    class: 'utec',
    name: 'Utec',
    image: './business/ctcForSchool/grey/logo-utec-grey.png'
  },
  {
    id: 'logo-3',
    class: 'innova',
    name: 'Innova',
    image: './business/ctcForSchool/grey/logo-innova-grey.png'
  },
  {
    id: 'logo-4',
    class: 'trener',
    name: 'Trener',
    image: './business/ctcForSchool/grey/logo-colegio-trener-grey.png'
  }
];

export const teacherList = [
  {
    title: 'Roman Huerta',
    description: 'Ingeniero Informático especializado en educación',
    icon: './teachers/roman.png',
    img_class: 'roman'
  },
  {
    title: 'Johanna Bobbio Paz',
    description: 'Licenciada en Educación (PUCP) - Especialista en Calidad',
    icon: './teachers/johanna.png',
    img_class: 'johanna'
  },
  {
    title: 'José Ortiz',
    description: 'Desarrollador de Software',
    icon: './teachers/jose.png',
    img_class: 'jose'
  },
  {
    title: 'Maricarmen Poma',
    description: 'Licenciada en Educación  (PUCP)',
    icon: './teachers/maricarmen.png',
    img_class: 'maricarmen'
  }
];

export const testimonyList = [
  {
    picture: 'https://i.imgur.com/q5CENmo.jpg',
    name: 'Diana Gomez',
    img_class: 'diana',
    subtitle: 'Madre - Colombia',
    flag: <ColombiaFlag />,
    description:
      '“Mi hija lleva clases muy entretenida, yo siento que aprende, se divierte y toma interés por la ciencia y la tecnología...”',
    typeTestimony: 'general',
    audioUrl: './mamadiana.mp3',
    pathName: '',
    courseName: ''
  },
  {
    picture: 'https://i.imgur.com/TjxEmXh.jpg',
    name: 'Gisela Tarazona',
    img_class: 'gisela',
    subtitle: 'Madre - Colombia',
    flag: <ColombiaFlag />,
    description:
      '“Me gusta mucho la metodología porque ayudan a los niños a pensar de manera distinta, sana y creativa. ¡SÚPER RECOMENDADO!”',
    typeTestimony: 'general',
    audioUrl: './mamafabricio.mp3',
    pathName: '',
    courseName: ''
  },
  {
    picture: 'https://i.imgur.com/m6zM0Xf.png',
    name: 'Fabricio',
    img_class: 'fabri',
    subtitle: '9 años - Perú',
    flag: <PeruFlag />,
    description:
      '“Aprendo cómo se crean  videojuegos, cómo piensan las computadoras...los cursos y compañeros son muy divertidos.”',
    typeTestimony: 'general',
    audioUrl: './fabricio.mp3',
    pathName: '',
    courseName: ''
  }
];

export const testimonyListCol = [
  {
    picture: 'https://i.imgur.com/q5CENmo.jpg',
    name: 'Diana Gomez',
    img_class: 'diana',
    subtitle: 'Madre - Colombia',
    flag: <ColombiaFlag />,
    description:
      '“Es muy bueno, y me gustó obtener más información sobre la aplicación de programación”',
    typeTestimony: 'general',
    audioUrl: './mamadiana.mp3',
    pathName: '',
    courseName: ''
  },
  {
    picture: 'https://i.imgur.com/TjxEmXh.jpg',
    name: 'Gisela Tarazona',
    img_class: 'gisela',
    subtitle: 'Madre - Colombia',
    flag: <ColombiaFlag />,
    description:
      '“Disfruté mucho de la sesión de programación, especialmente cuando el profesor me ayudó a mover a los enemigos más rápido”',
    typeTestimony: 'general',
    audioUrl: './mamafabricio.mp3',
    pathName: '',
    courseName: ''
  },
  {
    picture: 'https://i.imgur.com/m6zM0Xf.png',
    name: 'Fabricio',
    img_class: 'fabri',
    subtitle: '9 años - Colombia',
    flag: <ColombiaFlag />,
    description: '“Me encantó saber que puedo programar un videojuego además de jugar”',
    typeTestimony: 'general',
    audioUrl: './fabricio.mp3',
    pathName: '',
    courseName: ''
  }
];

export const testimonyListMx = [
  {
    picture: 'https://i.imgur.com/q5CENmo.jpg',
    name: 'Diana Gomez',
    img_class: 'diana',
    subtitle: 'Madre - México',
    flag: <MexicoFlag />,
    description: '“Me gustó resolver los problemas que tenía al igual que mis dudas”',
    typeTestimony: 'general',
    audioUrl: './mamadiana.mp3',
    pathName: '',
    courseName: ''
  },
  {
    picture: 'https://i.imgur.com/TjxEmXh.jpg',
    name: 'Gisela Tarazona',
    img_class: 'gisela',
    subtitle: 'Madre - México',
    flag: <MexicoFlag />,
    description:
      '“Me gusta mucho la metodología porque ayudan a los niños a pensar de manera distinta, sana y creativa. ¡SÚPER RECOMENDADO!”',
    typeTestimony: 'general',
    audioUrl: './mamafabricio.mp3',
    pathName: '',
    courseName: ''
  },
  {
    picture: 'https://i.imgur.com/m6zM0Xf.png',
    name: 'Fabricio',
    img_class: 'fabri',
    subtitle: '9 años - México',
    flag: <MexicoFlag />,
    description:
      '“Aprendo cómo se crean  videojuegos, cómo piensan las computadoras...los cursos y compañeros son muy divertidos.”',
    typeTestimony: 'general',
    audioUrl: './fabricio.mp3',
    pathName: '',
    courseName: ''
  }
];

export const imageHomeCarousel = [
  './jumbutron-cover-homepage.webp'
  /*   './jumbutron-cover-homepage.webp',
  './jumbutron-cover-homepage.webp', */
];
