import cx from 'classnames';
import { useState } from 'react';
import dynamic from 'next/dynamic';
import ReactPlayer from 'react-player';

import styles from './index.module.scss';

const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));

const InstitutionalVideo = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.text}>
          <Typography className={cx(styles.title, 'sm_text-center')} tag="h3">
            ¿Sabías que la innovación y la tecnología están transformando el panorama
            educativo en Colombia?
          </Typography>

          <Typography className={cx(styles.subtitle, 'sm_text-center')} tag="body-small">
            ¡Mira el video para conocer más!
          </Typography>
        </div>
        <div className={styles.imageCard} onClick={() => setIsPlaying(prev => !prev)}>
          {!isPlaying ? (
            <img
              src="./home/institutional-video/institutional-video-thumbnail.webp"
              alt="estudiante"
            />
          ) : (
            <ReactPlayer
              url="https://www.youtube.com/watch?v=Jkx5adteBU4&t=4s"
              playing={isPlaying}
              volume={0.5}
              width="100%"
              height="100%"
            />
          )}
        </div>
      </div>
      <div className={styles.icon}>
        <img src="/rayo.webp" alt="icono" />
      </div>
    </div>
  );
};

export default InstitutionalVideo;
